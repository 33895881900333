@import "../../assets/scss/index.scss";

.dashboard-card{
    width: 30%;
    padding: 32px;
    display: flex;
    // min-width: 346px;
    min-height: 224px;
    position: relative;
    background: $white;
    border-radius: 10px;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
    @media (min-width: 1700px) {
        min-width: 346px;
        width: auto;
    }
    @media (max-width: 768px) {
        width: 48%;
        min-width: auto;
    }
    @media (max-width: 580px) {
        padding: 16px;
        min-height: 124px;
    }
    h4{
        white-space: pre-wrap;
    }
    .card-icon{
        right: 0;
        bottom: 0;
        width: 112px;
        height: 112px;
        opacity: 0.05;
        position: absolute;
        img{
            width: 100%;
            height: 100%;
        }
        svg{
            width: 100%;
            height: 100%;
            path{
                fill: $midGray
            }
        }
    }
}

.detail-page-cards{
    margin-block: 32px !important;
    display: grid !important;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 32px !important;
    @media (max-width: 1200px) {
        grid-template-columns: 1fr 1fr 1fr;
    }
    @media (max-width: 580px) {
        margin-top: 16px !important;
        grid-template-columns: 1fr 1fr;
        grid-gap: 16px !important;
    }
    .dashboard-card{
        @media (min-width: 1700px) {
            min-width: auto;
        }
        width: auto;
    }
}