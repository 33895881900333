@import "../../assets/scss/index.scss";

.card-wrapper{
    background: $white;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    min-height: 300px;
    max-width: 480px;
    margin-inline: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    @media (max-width:570px) {
        max-width: 95%;
    }
}