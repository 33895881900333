@import "../../assets/scss/index.scss";

// .account-cards {
//     margin-top: 40px;
//     display: flex;
//     gap: 32px;
//     @media (max-width: 992px) {
//         flex-wrap: wrap;
//     }
//     .account-card-info {
//         flex-direction: column;
//         position: relative;
//         padding: 32px;
//         justify-content: flex-start !important;
//         max-width: inherit !important;
//         margin-inline: unset !important;

//         .profile-picture {
//             border-radius: 20px;
//             border: 4px solid $white;
//             position: absolute;
//             left: 32px;
//             top: -32px;
//             box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);

//             img {
//                 border-radius: inherit;
//             }
//         }

//         .account-edit {
//             display: flex;
//             align-items: center;
//             justify-content: space-between;
//             width: 100%;

//             .profile-edit-button {
//                 height: 32px;
//                 min-width: 128px;
//                 border-radius: 6px;
//             }
//         }

//         .account-detail {
//             padding-top: 25px;
//             width: 100%;

//             ul {
//                 li {
//                     display: flex;
//                     gap: 20px;
//                     align-items: baseline;
//                     &:not(:last-child) {
//                         padding-bottom: 25px;
//                     }
//                     @media (max-width: 600px) {
//                         gap: 10px;
//                     }

//                     p {
//                         min-width: 190px;
//                         max-width: 170px;
//                         display: flex;
//                         align-items: baseline;
//                         gap: 10px;
//                         @media (max-width: 1300px) {
//                             min-width: 130px;
//                             max-width: 130px;
//                         }
//                         @media (max-width: 600px) {
//                             min-width: 130px;
//                             max-width: 130px;
//                             align-items: first baseline;
//                         }
//                         img {
//                             width: 14px;
//                         }
//                     }
//                     span{
//                         word-break: break-all;
//                     }
//                 }
//             }
//         }
//     }
// }

.account-edit-modal{
    max-width: 536px;
    min-width: 536px;
    margin-block: 50px;
    @media (max-width: 580px) {
        min-width: 95%;
        width: 95% !important;
    }
    .modal-footer-buttons{
        justify-content: flex-start;
        gap: 12px;
        @media (max-width: 580px) {
            flex-direction: row;
        }
        button{
            @media (max-width: 580px) {
                min-width: 100px;
                width: 100%;
            }
        }
    }
    .ant-modal-content{
        padding: 36px;
        .account-edit-modal-content{
            h3{
                font-size: 32px;
            }
            .edit-profile-picture{
                position: relative;
                border: 4px solid #FFFFFF;
                border-radius: 20px;
                width: 110px;
                object-fit: cover;
                box-shadow: $boxShadow;
                img{
                    border-radius: inherit;
                }
                .edit-icon{
                    position: absolute;
                    right: -1px;
                    bottom: -1px;
                    background: $white;
                    border-radius: 6px 0 20px 0;
                    width: 32px;
                    height: 32px;
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                    img{
                        width: 14px;
                        height: 14px;
                        border-radius: 0;
                    }
                }
            }
        }
    }
}