.white{
    color: #FFFFFF;
}
.blue{
    color: #2152AE;
}
.darkBlue{
    color: #071C43;
}
.lightBlue{
    color: #7D91A0;
}
.darkGray{
    color: #5D5D5D;
}
.midGray{
    color: #CDCDCD;
}
.lightGray{
    color: #F3F3F3;
}
.bgGray{
    color: #F8F8F8;
}
.green{
    color: #07BE7C;
}
.pink{
    color: #FF6464;
}