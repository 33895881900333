@import '../../assets/scss/index.scss';

.profile{
    display: flex;
    padding: 16px;
    max-width: 536px;
    background: $white;
    border-radius: 10px;
    margin-bottom: 16px;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
    @media (max-width: 992px) {
        max-width: 100%;
    }
    .profile-content{
        gap: 8px;
        display: flex;
        align-items: center;
        span{
            line-height: 20px;
        }
        img{
            width: 30px;
            height: 30px;
            border-radius: 50%;
            border: 2px solid $white;
        }
    }
    .profile-status{
        button{
            color: $white;
            min-width: 70px;
            font-size: 12px;
            font-weight: 700;
            min-height: 30px;
            background: $green;
            border-radius: 20px;
            cursor: auto;
            text-transform: uppercase;
            box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
            &.offline{
                color: $darkGray;
                background: $midGray;
            }
        }
    }
}