@import "../../assets/scss/index.scss";

.dashboard-login {
    min-height: 100vh;
    width: 100%;
    background-image: url('../../assets/images/login-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    padding-block: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    .login-card-content{
        padding-block: 60px 45px;
        text-align: center;
        h4{
            font-size: 32px;
            margin-bottom: 45px;
        }
        .login-card-logo{
            margin-bottom: 40px;
            max-width: 148px;
            margin-inline: auto;
        }
        .ant-alert-error{
            max-width: 352px;
            min-width: 352px;
            align-items: baseline;
            margin-bottom: 10px;
        }
        .login-input{
            max-width: 352px;
            min-width: 352px;
            width: 100%;
            border-bottom: 1px solid $midGray;
            padding: 10px 28px;
            position: relative;
            margin-bottom: 25px;
            @media (max-width: 450px) {
                max-width: 95%;
                min-width: auto;
            }
            .error{
                left: 0;
                width: 100%;
                bottom: -16px;
                font-size: 12px;
                color: #FF6464;
                text-align: center;
                position: absolute;
            }
            img{
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);

                &.lock-eye{
                    right: 0;
                    left: auto;
                    cursor: pointer;
                }
            }
        }
        .input-checked{
            margin-top: 30px;
            text-align: left;
            .checkbox-container input:checked + .checkmark:after {
                content: "";
                display: block;
                position: absolute;
                left: 7px;
                width: 10px;
                height: 10px;
                background-color: $blue;
                border-radius: 3px;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
            .checkbox-container{
                cursor: pointer;
                display: flex;
                align-items: center;
                input {
                    display: none;
                }
                .checkmark {
                    position: relative;
                    display: inline-block;
                    width: 20px;
                    height: 20px;
                    border: 2px solid $darkGray;
                    margin-right: 10px;
                    border-radius: 6px;
                }
            }
        }
        .login-button{
            button{
                height: 48px;
                width: 100%;
                margin-block: 35px;
            }
        }
    }
}
.modal-content{
    text-align: center;
    text-align: center;
    max-width: 372px;
    margin-inline: auto;
    padding-block: 35px;
    @media (max-width: 450px) {
        max-width: 95%;
    }
    .login-input{
        max-width: 352px;
        min-width: 352px;
        width: 100%;
        border-bottom: 1px solid $midGray;
        padding: 10px 28px;
        position: relative;
        margin-bottom: 25px;
        margin-inline: auto;
        @media (max-width: 450px) {
            max-width: 95%;
            min-width: auto;
        }
        .error{
            left: 0;
            width: 100%;
            bottom: -20px;
            font-size: 12px;
            color: #FF6464;
            text-align: center;
            position: absolute;
        }
    }
}
            