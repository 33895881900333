@import "../../assets/scss/index.scss";

.label-select{
    select{
        width: 100%;
        height: 32px;
        margin-top: 7px;
        text-align: left;
        border-radius: 10px;
        padding-inline: 12px;
        -moz-appearance: none;
        background: transparent;
        -webkit-appearance: none;
        backdrop-filter: blur(4px);
        border: 1px solid $midGray;
        font-size: 14px !important;
        color: $darkBlue !important;
        background-repeat: no-repeat;
        background-position: center right 15px;
        background-image: url(../../assets/images/dropdown-arrow.svg);
        &:focus-visible{
            outline: none;
        }
    }
}