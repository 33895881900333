$white: #FFFFFF;
$blue: #2152AE;
$darkBlue: #071C43;
$lightBlue: #7D91A0;
$darkGray: #5D5D5D;
$midGray: #CDCDCD;
$lightGray: #F3F3F3;
$lightGray: #CDCDCD;
$bgGray: #F8F8F8;
$green: #07BE7C;
$pink: #FF6464;

$boxShadow: 0px 4px 20px rgba(0, 0, 0, 0.05);