@import "variables.scss";
@import "reset.scss";
@import "global.scss";

.account-cards {
    margin-top: 40px;
    display: flex;
    gap: 32px;
    @media (max-width: 992px) {
        flex-wrap: wrap;
    }
    &.admin-user-card{
        // min-height: 272px;
        .account-card-info{
            .account-detail{
                display: flex;
                gap: 40px;
                @media (max-width: 992px) {
                    flex-wrap: wrap;
                    gap: 0px;
                }
                ul{
                    li{
                        padding-bottom: 25px;
                        p{
                            min-width: 140px;
                            @media (max-width: 580px) {
                                min-width: 100px;
                                max-width: 100px;
                            }
                        }
                    }
                }
            }
        }
    }
    .account-card-info {
        flex-direction: column;
        position: relative;
        padding: 32px;
        justify-content: flex-start !important;
        max-width: inherit !important;
        margin-inline: unset !important;

        .profile-picture {
            border-radius: 20px;
            border: 4px solid $white;
            position: absolute;
            left: 32px;
            top: -32px;
            box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);

            img {
                border-radius: inherit;
            }
        }

        .account-edit {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            .profile-edit-button {
                height: 32px;
                min-width: 128px;
                border-radius: 6px;
            }
        }

        .account-detail {
            padding-top: 25px;
            width: 100%;

            ul {
                li {
                    display: flex;
                    gap: 20px;
                    align-items: baseline;
                    &:not(:last-child) {
                        padding-bottom: 25px;
                    }
                    @media (max-width: 600px) {
                        gap: 10px;
                    }

                    p {
                        min-width: 190px;
                        max-width: 170px;
                        display: flex;
                        align-items: baseline;
                        gap: 10px;
                        @media (max-width: 1300px) {
                            min-width: 130px;
                            max-width: 130px;
                        }
                        @media (max-width: 600px) {
                            min-width: 130px;
                            max-width: 130px;
                            align-items: first baseline;
                        }
                        img {
                            width: 14px;
                        }
                    }
                    span{
                        word-break: break-all;
                    }
                }
            }
        }
    }
}

// admin dashboard
.admin-dashboard{
    margin-top: 7px;
    display: flex;
    gap: 32px;
    flex-wrap: wrap;
    @media (max-width: 768px) {
        gap: 16px;
    }
    @media (max-width: 580px) {
        gap: 8px;
    }
}

// table-sorter
.sorting-table{
    .ant-table-thead{
        .ant-table-cell{
            div{
                display: flex;
                align-items: center;
                gap: 7px;
                background: none;
                border: none;
                outline: none;
                padding: 0px;
            }
        }
    }
    .ant-table-tbody{
        tr{
            &:hover{
                td{
                    cursor: pointer;
                    background: rgba(33, 82, 174, 0.1) !important;
                    font-weight: 700;
                    &:first-child{
                        border-top-left-radius: 10px;
                        border-bottom-left-radius: 10px;
                    }
                    &:last-child{
                        border-top-right-radius: 10px;
                        border-bottom-right-radius: 10px;
                    }
                }
            }
        }
    }
}
.blocked-table{
    .ant-table-tbody{
        tr{
            &:hover{
                td{
                    cursor: unset;
                    background: transparent !important;
                    font-weight: unset;
                    &:first-child{
                        border-top-left-radius: unset;
                        border-bottom-left-radius: unset;
                    }
                    &:last-child{
                        border-top-right-radius: unset;
                        border-bottom-right-radius: unset;
                    }
                }
            }
        }
    }
}