@import "../../assets/scss/index.scss";

.dashboard-table{
    padding: 32px;
    background-color: $white;
    border-radius: 10px;
    margin-top: 16px;
    margin-bottom: 23px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
    .ant-table-wrapper {
        overflow-x: auto;
    }
    table>thead>tr:first-child >*:first-child{
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
    }
    table>thead>tr:last-child >*:last-child{
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
    }
    .ant-table-thead{
        tr{
            th{
                white-space: nowrap;
                background: $bgGray;
                border-bottom: 0;
                color: $darkGray;
                font-weight: 600;
                font-size: 14px;
                &::before{
                    display: none;
                }
            }
        }
    }
    .ant-table-tbody{
        tr{
            td{
                white-space: nowrap;
                color: $darkGray;
                font-weight: 400;
                font-size: 14px;
            }
        }
    }
}