@import "../../assets/scss/index.scss";

input{
    text-align: center;
    width: 100%;
    background-color: transparent;
    outline: none;
    font-size: 18px !important;
    font-weight: 400 !important;
    color: $darkGray !important;
    &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0px 1000px white inset !important;
        box-shadow: 0 0 0px 1000px white inset !important;
    }
  }