.ant-modal-close{
    top: 14px;
    inset-inline-end: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.ant-modal-header{
    display: none;
}
.forgot-password-modal{
    width: 544px !important;
    @media (max-width: 576px) {
        width: 95% !important;
    }
}