@tailwind base;
@tailwind components;
@tailwind utilities;
@import "./assets/scss/index.scss";

//for remove input type number arrows
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
// end
::-webkit-scrollbar{
  width: 4px;
  height: 4px;
  border-radius: 10px;
  background: $midGray;
}
::-webkit-scrollbar-thumb{
  border-radius: 10px;
  background: $blue;
}
.dashboard-layout{
  min-height: 100vh;
  background: $bgGray;
  border-radius: 20px;
  padding: 16px 32px 16px 16px;
  @media (max-width: 992px) {
    padding: 24px;
  }
  @media (max-width: 580px) {
    padding: 20px;
  }
  .dashboard-content {
    margin-left: 265px;
    padding-left: 32px;
    @media (max-width: 992px) {
      margin-left: 0;
      padding-left: 0px;
    }
  }
  .show-tab{
    display: none;
    @media (max-width: 992px) {
      display: flex;
      justify-content: space-between;
      margin-bottom: 25px;
      margin-top: 10px;
    }
    svg{
        width: 105px;
    }
  }
}
// Modal
.modal-footer-buttons{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 32px;
  @media (max-width: 450px) {
    flex-direction: column;
    gap: 15px;
    button{
      width: 100%;
    }
  }
}
.dashboard-heading{
  padding-block: 16px;
  h2{
      font-size: 32px;
      @media (max-width: 992px) {
          font-size: 26px;
      }
  }
}
.select-field{
  top: 29px;
  right: 15px;
  width: 65px;
  height: 30px;
  position: absolute;
  border-left: 1px solid $midGray;
  select{
      width: 100%;
      height: 100%;
      font-size: 14px;
      cursor: pointer;
      font-weight: 400;
      color: $darkBlue;
      padding-left: 15px;
      -moz-appearance: none;
      background: transparent;
      background-repeat: no-repeat;
      background-position: center right;
      background-image: url(./assets/images/dropdown-arrow.svg);
      -webkit-appearance: none;
      &:focus-visible{
          outline: none;
      }
  }
}

// error
.error{
  font-size: 12px;
  color: #FF6464;
}