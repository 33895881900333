@import "../../assets/scss/index.scss";

.dashboard-pagination{
    display: flex;
    justify-content: center;
    align-items: center;
    .ant-pagination{
        li{
            &:first-child, &:last-child{
                background: $white;
                box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1);
                button{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
            &.ant-pagination-item{
                box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1);
                background: $white;
                border-radius: 6px;
                a{
                    font-weight: 600;
                    color: $darkBlue;
                }
            }
            &.ant-pagination-item-active{
                box-shadow: 4px 4px 10px rgba(21, 63, 169, 0.4);
                background: #3fbda5;
                a{
                    color: $white;
                }
            }
            &.ant-pagination-next{
                background: $white;
                box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1);
            }
            &.ant-pagination-options{
                display: none;
            }
            &.ant-pagination-item-link{
                display: flex;
                align-items: center;
                justify-content: center;
            }
            &.ant-pagination-next{
                button{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
            .ant-pagination-item-link{
                .ant-pagination-item-ellipsis{
                    color: $darkBlue;
                }
            }
        }
    }
}