@import "../../assets/scss/index.scss";

.dashboard-sidebar{
    position: fixed;
    width: 265px;
    background: $white;
    border-radius: 10px;
    height: calc(100% - 32px);
    overflow-y: auto;
    padding-block: 30px;
    z-index: 2;
    &.tab-sidebar{
      padding: 24px;
      width: 265px;
      left: 10px;
      top: 10px;
      height: calc(100% - 20px);
      @media (max-width: 570px) {
        width: calc(100% - 20px);
      }
    }
    @media (max-width: 992px) {
      padding: 0;
      width: 0;
    }
    .dashboard-logo {
      padding-inline: 30px;
      margin-bottom: 40px;
      display: flex;
      justify-content: flex-start;
      @media (max-width: 992px) {
        padding-inline: 0px;
        justify-content: space-between;
      }
      .show-tab{
        margin: 0;
      }
      img{
        margin-inline: auto;
        &:last-child{
            @media (max-width: 992px) {
                width: 105px;
            }
        }
        margin-inline: inherit;
      }
    }
    ul{
      li{
        margin-right: 16px;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        &.active-list{
          position: relative;
          &::before{
            content: '';
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            height: 32px;
            width: 4px;
            border-radius: 10px;
            box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
            background-color: $white;
          }
          background-color: #3fbda5;
          a{
            svg{
              path{
                fill: $white;
              }
            }
            p{
              color: $white;
            }
          }
        }
        a{
          display: flex;
          align-items: center;
          gap: 13px;
          padding-block: 20px;
          padding-inline: 30px;
          @media (max-width: 992px) {
            padding-inline: 0px;
          }
          p{
            font-size: 16px;
            color: $darkGray;
          }
        }
        &:not(:last-child){
          margin-bottom: 20px;
        }
      }
    }
  }