@import "../../assets/scss/index.scss";

.label-input{
    label{
        min-height: 22px;
        display: block;
    }
    input{
        height: 32px;
        margin-top: 7px;
        text-align: left;
        border-radius: 10px;
        padding-inline: 12px;
        backdrop-filter: blur(4px);
        border: 1px solid $midGray;
        font-size: 14px !important;
        color: $darkBlue !important;
    }
}