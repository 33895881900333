@import '../../assets/scss/index.scss';

.remove-block-buttons{
    display: flex;
    align-items: center;
    gap: 32px;
}

.ant-select-selector{
    padding: 0px;
}