@import "../../assets/scss/index.scss";

.primary-button{
    border-radius: 10px;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-inline: 10px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    color: $white;
    min-width: 176px;
    background: #3fbda5 !important;
    &.secondary-button{
        background: $darkGray !important;
    }
    &.disabled{
        opacity: 0.7;
        cursor: not-allowed;
    }
    &.unblock-btn{
        height: 32px;
        font-size: 12px;
        min-width: 80px;
        font-family: 'Inter', sans-serif;
    }
    @media (max-width: 768px) {
        height: 48px;
    }
}