@import "../../assets/scss/index.scss";

.dashboard-cards{
    gap: 32px;
    display: flex;
    margin-top: 7px;
    align-items: center;
    margin-bottom: 16px;
    justify-content: space-between;
    @media (max-width: 992px) {
        margin-top: 0;
    }
    @media (max-width: 1250px) {
        gap: 12px;
        flex-wrap: wrap;
    }
    &.client-cards{
        .meeting-link-card{
            @media (max-width: 600px) {
                flex-direction: column;
                align-items: flex-start;
                gap: 10px;
            }
            &:last-child{
                width: 100%;
            }
        }
        .left-content{
            max-width: 210px;
            @media (max-width: 600px) {
                width: 100%;
            }
        }
        .client-content{
            width: calc(100% - 225px);
            height: 32px;
            position: relative;
            @media (max-width: 600px) {
                width: 100%;
            }
            button{
                top: 0;
                right: 0;
                bottom: 0;
                height: 100%;
                min-width: 128px;
                position: absolute;
                @media (max-width: 600px) {
                    min-width: 40px;
                }
            }
            form{
                height: 100%;
                input{
                    padding-right: 135px;
                    height: 100%;
                    text-align: left;
                    padding-left: 10px;
                    border-radius: 10px;
                    backdrop-filter: blur(4px);
                    font-size: 14px !important;
                    font-weight: 400 !important;
                    border: 1px solid #CDCDCD;
                    @media (max-width: 600px) {
                        padding-right: 50px;
                    }
                }
            }
        }
    }
    .meeting-link-card{
        padding: 16px;
        display: flex;
        align-items: center;
        border-radius: 10px;
        background: #FFFFFF;
        justify-content: space-between;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
        @media (max-width: 580px) {
            padding: 12px 8px 12px 16px;
            align-items: flex-end;
        }
        &:first-child{
            width: 67%;
            @media (max-width: 1250px) {
                width: 100%;
            }
        }
        &:last-child{
            @media (max-width: 1250px) {
                width: 100%;
            }
            width: 33%;
        }
        .left-content{
            gap: 5px;
            display: flex;
            align-items: center;
            @media (max-width: 580px) {
                flex-direction: column;
                align-items: flex-start;
                width: 90%;
            }
            span{
                gap: 5px;
                display: flex;
                align-items: center;    
            }
        }
        .right-content{
            width: 32px;
            height: 32px;
            display: flex;
            cursor: pointer;
            background: $blue;
            border-radius: 8px;
            align-items: center;
            justify-content: center;
            &.copy-text{
                width: auto;
                padding-inline: 10px;
                color: $white;
            }
            img{
                width: 20px;
                height: 20px;
            }
        }
    }
}
.twilio-window{
    padding: 32px;
    display: flex;
    background: $white;
    border-radius: 10px;
    min-height: calc(100vh - 183px);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
    @media (max-width: 992px) {
        box-shadow: none;
        min-height: calc(100vh - 436px);
        background: transparent;
    }
    .twilio-content{
        width: 100%;
        display: flex;
        border-radius: 6px;
        position: relative;
        background: $bgGray;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border: 1px solid $midGray;
        // @media (max-width: 992px) {
        //     display: none;
        // }
        h2{
            width: 402px;
            font-size: 62px;
            text-align: center;
            line-height: 91.77px;
        }
        .zoom-window{
            right: 16px;
            bottom: 16px;
            cursor: pointer;
            position: absolute;
        }
    }
}
.start-Call{
    display: flex;
    margin-block: 16px;
    align-items: center;
    justify-content: center;
}

// modal css
.rate-per-minute-modal{
    .modal-footer-buttons{
        gap: 12px;
        justify-content: center;
        @media (max-width:450px) {
            flex-direction: row;
            button{
                width: 48%;
                min-width: 48%;
            }
        }
    }
}

.call-modal{
    .ant-modal-content{
        padding: 60px 20px;
        @media (max-width: 580px) {
            padding: 40px 20px;
        }
        .ant-modal-body{
            width: 70%;
            text-align: center;
            margin-inline: auto;
            @media (max-width: 580px) {
                width: 90%;
            }
        }
        .ant-modal-footer{
            margin-top: 60px;
            @media (max-width: 580px) {
                margin-top: 30px;
            }
        }
    }
}

// clients dashboard cards
.client-profile-cards{
    gap: 16px;
    display: flex;
    align-items: center;
    @media (max-width:1250px) {
        flex-wrap: wrap;
    }
    @media (max-width:992px) {
        flex-direction: column;
    }
    .profile{
        width: 33%;
        margin-bottom: 0 !important;
        @media (max-width:992px) {
            width: 100%;
        }
    }

}


// room css
.room{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    width: 100%;
    .local-participant{
        width: 50%;
        video{
            width: 100%;
        }
    }
    .remote-participants{
        width: 50%;
        video{
            width: 100%;
        }
    }
}